import Vue from "vue";
import VueRouter from "vue-router";
import Credentials from "./Credentials/index";
import HomeView from "@/views/main/HomeView.vue";
import ItStockAdjustmentRouter from "./modules/IT/index";
import ContainersRouter from "./modules/Operations/Containers/index";
import MasterDataRouter from "./modules/MasterData/index";
import SalesItemRouter from "@/router/modules/Sales/Items/index";
import Reports from "./modules/Reports/index";
import SalesReports from "./modules/Reports/SalesReports/index";
import WarehouseInventoryRouter from "@/router/modules/Operations/Inventory/index";
import DispatchesRouter from "@/router/modules/Logistics/Dispatches/index";
import OldStockTransfer from "@/router/modules/Operations/StockTransfer/index";
import AcctItemCosting from "@/router/modules/Accounting/ItemCosting/index";
import StockRequest from "@/router/modules/Operations/StockRequest/index";
import StockTransferNew from "@/router/modules/Operations/StockTransferNew/index";
import AcctStatementOfAccount from "@/router/modules/Accounting/StatementOfAccount/index";
import AcctCreditMemo from "@/router/modules/Accounting/CreditMemo/index";
import AcctDebitMemo from "@/router/modules/Accounting/DebitMemo/index";
import AcctPayments from "@/router/modules/Accounting/Payments/index";
import InventoryReports from "./modules/Reports/InventoryReports/index";
import StockVariance from "./modules/Operations/StockVariance/index";
import AccountingReports from "./modules/Reports/AccountingReports/index";
import Inbound from "./modules/Operations/Inbound/index";
import Outbound from "./modules/Operations/Outbound/index";
import SystemSettings from "./Main/SystemSettings/SystemSettings";
import noaccess from "@/views/main/no-access.vue";
import repository from "@/api/credentials";
import salesquotationnew from "@/router/modules/Sales/SalesQuotation/index";
import SalesOrderGroupComponent from "@/router/modules/Sales/SalesOrdersNew/index";
import newwpf from "@/router/modules/Operations/WPF/index";
import newCF from "@/router/modules/Operations/CF/index";
import PackingListRouter from "@/router/modules/Sales/PackingList/index";
import StockCardComponent from "@/router/modules/Operations/StockCards/index";
import PromosRouter from "@/router/modules/Sales/Promos/index";
import ChangePassword from "@/router/Utils/index";
import WarehouseUser from "@/router/modules/Operations/WarehouseUser/index";
import AccountingUser from "@/router/modules/Accounting/AccountingUser/index";
import AccountingBanks from "@/router/modules/Accounting/Banks/index";
import PackingListHistory from "@/router/modules/Accounting/PackingListHistory/index";

import store from '@/store';
import ContainerDiscrepancy from "@/router/modules/Operations/ContainerDiscrepancy/index"
import StocksInquiry from "@/router/modules/Sales/StocksInquiry/index"

Vue.use(VueRouter);
const routes = [
    ...Credentials,
    {
        path: "/home",
        name: "home",
        title: "Home",
        component: HomeView,
        beforeEnter: (to, from, next) => {
            repository
                .authentication()
                .then(() => {
                    store.dispatch("credentials");
                    store.dispatch('GeneralSettings');
                    store.dispatch("renderSideNav");
                    if (window.location.search) {
                        const url = new URL(window.location.href)
                        const params = new URLSearchParams(url.search.slice(1))
                        params.delete('username')
                        params.delete('password')
                        window.history.replaceState(
                            {},
                            '',
                            `${window.location.pathname}${window.location.hash}`,
                        )
                    }
                    next();
                })
                .catch(() => {
                    localStorage.setItem("last_visit", to.fullPath);
                    localStorage.removeItem("token");
                    if(localStorage.getItem('userLogged') || localStorage.getItem('token2')){
                        let payload = JSON.parse(localStorage.getItem('userLogged'));
                          store.dispatch("login", payload).then((success) => {
                            localStorage.setItem("token", success.access_token);
                            store.dispatch("renderSideNav");
                            next({ name: "home" })
                            if(localStorage.getItem("token")){
                                localStorage.removeItem('userLogged')
                                localStorage.removeItem('token2')
                            }

                          })
                    }else{
                        return next({ name: "login" });
                    }
                });
        },
        children: [
            ...MasterDataRouter,
            ...ItStockAdjustmentRouter,
            ...ContainersRouter,
            ...SalesItemRouter,
            ...Reports,
            ...SalesReports,
            ...WarehouseInventoryRouter,
            ...DispatchesRouter,
            ...OldStockTransfer,
            ...AcctItemCosting,
            ...StockRequest,
            ...StockTransferNew,
            ...AcctStatementOfAccount,
            ...AcctCreditMemo,
            ...AcctDebitMemo,
            ...AcctPayments,
            ...InventoryReports,
            ...StockVariance,
            ...AccountingReports,
            ...Inbound,
            ...Outbound,
            ...SystemSettings,
            ...salesquotationnew,
            ...SalesOrderGroupComponent,
            ...newwpf,
            ...newCF,
            ...PackingListRouter,
            ...StockCardComponent,
            ...PromosRouter,
            ...ChangePassword,
            ...WarehouseUser,
            ...AccountingUser,
            ...AccountingBanks,
            ...PackingListHistory,
            ...ContainerDiscrepancy,
            ...StocksInquiry,
            {
                path: "/no-access",
                component: noaccess,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
        meta: {
            requiresAuth: true,
            title: "Home",
        },
    },
];
const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !localStorage.getItem("token")) {
        next();
    } else if (!to.meta.requiresAuth && localStorage.getItem("token")) {
        next("/home");
    } else {
        if (typeof to.meta.title !== "undefined") {
            document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
        } else {
            document.title = `${process.env.VUE_APP_TITLE}`;
        }
        next();
    }
});

export default router;
